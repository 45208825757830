import React, {useCallback, useEffect, useRef, useState} from 'react';
import './styles.scss'
import {Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import InfiniteScrollTable from "../table/InfiniteScrollTable";
import TableHeader from "./components/TableHeader";
import TableRow from "./components/TableRow";
import LoadingSegment from "../LoadingSegment";
import {toast} from "react-toastify";
import {defaultFunc} from "../../utils/stateHelper";
import {
    caseStates,
    DEFAULT_REPRESENTATION_TYPE_CODE,
    PAGE_SIZE,
    REPRESENTATION_DICTIONARY_NAME
} from "../../constants/options";
import {useLazyGetForQuery, useSaveMutation} from "../../store/api/dictionaryApi";
import {getRepresentation, setRepresentationToLocalStorage} from "../../utils/localeStorageHelper";
import Footer from "./components/Footer";
import TableBlockHeader from "./components/TableBlockHeader";
import {useDeepEffect} from "../../hooks/useDeepEffect";
import {ACTIONS} from "../../constants/fieldTypes";
import {isNumber} from "lodash";

const getColumnsByMode = (cols, mode, representation, withActions) => {
    const viewCol = (col) => {
        const colSettings = col.defaultRepresentations?.find(dr => dr.mode === mode)
        return colSettings ? {
            ...col,
            order: colSettings.order
        } : null
    };

    const viewColFromRepresentation = (col) => {
        const field = representation?.properties.fields?.find(v => v.propName === col.name);
        return field ? {
            ...col,
            order: field.order
        } : null
    }

    const sortCols = cols
        .map(col => {
            return (!representation && isNumber(mode))
                ? viewCol(col)
                : (representation ? viewColFromRepresentation(col) : col)
        })
        .filter(col => col)
        .sort((a, b) => a.order - b.order);

    if (withActions) sortCols.push({
        type: ACTIONS,
        name: 'actions',
        displayNameKey: ' '
    })

    return sortCols;
}

const TableBlock = ({
                        className,
                        settings,
                        isTab,
                        isModesTab, //выключит зависимость отображаемого списка колонок от значений в defaultRepresentations для mode
                        editBtn,
                        getListQuery = defaultFunc,
                        getIdsQuery = defaultFunc,
                        deleteRow = defaultFunc,
                        getCountersQuery = defaultFunc,
                        exportToExcelFunc = defaultFunc,
                        importFromExcelFunc = defaultFunc,
                        getActionsFunc = defaultFunc,
                        actionFunc = defaultFunc,
                        changeFieldValue = defaultFunc,
                        clickToggle,
                        preFilters,
                        withoutRepresentations,
                        withFooter,
                        idKey = '',
                        isModal,
                        disabledFilters,
                        setCheckedRows,
                        withBacklights
                    }) => {
    const {t} = useTranslation();
    const {
        columns: cols = [],
        name,
        withoutSearch,
        withoutHeader,
        filters: withFilters = true,
        canDelete,
        modes: tabs,
        withCounts,
        noLabel,
        customClassNameRow,
        customFilters,
        openModalOnClick = true,
        defaultMode,
        canExportToExcel,
        canImportFromExcel,
        canCreateByForm,
        canCopy,
        canMassChange,
        selectedToTop,
        customComponent,
        disableCreateWithoutPlatform,
        withActions,
        autoRefreshTime
    } = settings;
    const [mode, setMode] = useState(defaultMode);

    const [loading, setLoading] = useState(false);

    const [getList, {
        data: {
            items: data = [],
            totalCount
        } = {},
        isFetching,
        isError
    }] = getListQuery();

    const [getIds] = getIdsQuery();

    const pageSize = PAGE_SIZE;

    const [searchQuery, setSearchQuery] = useState('');

    const [filters, setFilters] = useState(preFilters || {});
    const [customFieldsFilters, setCustomFieldsFilters] = useState([]);
    const [sort, setSort] = useState({});

    const [backlights, setBacklights] = useState(withBacklights && [])
    const [backlightsIsFilter, setBacklightsIsFilter] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        setCheckedRows && setCheckedRows(selectedItems);
    }, [setCheckedRows, selectedItems])

    const [deleteList, {isLoading: deleteLoading}] = deleteRow();

    const [getCounters, {data: counterList}] = getCountersQuery();
    const [getActions, {data: actions, isLoading: actionsLoading}] = getActionsFunc();

    const [getRepresentations, {isLoading: getRepresentationsLoading}] = useLazyGetForQuery();
    const [representations, setRepresentations] = useState(null);
    const [representation, setRepresentation] = useState(null);

    const representationId = getRepresentation(name);

    const columns = getColumnsByMode(cols, !isModesTab && mode, representation, withActions)

    const refHeader = useRef();
    const refCustomFilters = useRef();
    const refFooter = useRef();

    const defaultRepresentationName = `${DEFAULT_REPRESENTATION_TYPE_CODE}-${name}`;
    const [saveRepresentation, {isLoading: loadingViewSave}] = useSaveMutation();


    const [filtersFromRepresentationSet, setFiltersFromRepresentationSet] = useState(!withFilters);

    const saveFiltersInRepresentation = () => {
        (totalCount !== undefined) && getViews(res => {
            if (res) {
                const repr = representationId && res.find(r => r.id === representationId);
                if (repr || !representationId) {
                    let allFilters = Object.keys(filters).map(key => ({
                        propName: key,
                        filter: filters[key]
                    }));

                    saveRepresentation({
                        name: REPRESENTATION_DICTIONARY_NAME,
                        form: {
                            ...(repr || {}),
                            name: repr ? repr.name : defaultRepresentationName,
                            properties: {
                                ...(repr?.properties || {}),
                                entityType: repr ? repr.properties?.entityType : DEFAULT_REPRESENTATION_TYPE_CODE,
                                filter: allFilters,
                                sorting: sort?.name ? {
                                    propName: sort.name,
                                    desc: sort.desc,
                                    isCustom: !!sort.isCustom
                                } : null,
                                customFieldsFilter: customFieldsFilters,
                                backlightFilter: backlightsIsFilter ? backlights?.map(b => b.id) : undefined
                            }
                        }
                    })
                        .unwrap()
                        .then(() => console.log('Representation is saved'))
                        .catch((e) => toast.error(t(e?.error || 'error')))
                }
            }
        });
    }

    useDeepEffect(() => {
        if (!isModal && filtersFromRepresentationSet && withFilters) {
            saveFiltersInRepresentation();
        }
    }, [filters, sort, customFieldsFilters, !backlightsIsFilter || backlights])

    const getActionsRequest = () => getActions({ids: selectedItems, name})

    useEffect(() => {
        getActionsRequest();
    }, [selectedItems]);

    useDeepEffect(() => {
        (filtersFromRepresentationSet && name) && loadList();
    }, [filters, sort, mode, name, searchQuery, customFieldsFilters, filtersFromRepresentationSet, !backlightsIsFilter || backlights]);

    const timerRef = useRef();
    const [autoRefreshIsOn, setAutoRefreshIsOn] = useState(false);

    const addInterval = () => timerRef.current = setInterval(refresh, autoRefreshTime);
    const deleteInterval = () => timerRef.current && clearInterval(timerRef.current);

    useDeepEffect(() => {
        if (autoRefreshTime) {
            if (autoRefreshIsOn) addInterval();
            else deleteInterval();
        }
        return deleteInterval;
    }, [autoRefreshIsOn, data?.length, autoRefreshTime, filters, sort, mode, name, searchQuery, customFieldsFilters, filtersFromRepresentationSet, !backlightsIsFilter || backlights]);

    const visibilityChange = () => {
        if (document.hidden && autoRefreshTime) {
            setAutoRefreshIsOn(false);
        } else {
            setAutoRefreshIsOn(true);
        }
    }

    useEffect(() => {
        setAutoRefreshIsOn(!!autoRefreshTime);
        document.addEventListener("visibilitychange", visibilityChange);
        return () =>  document.removeEventListener("visibilitychange", visibilityChange);
    }, [autoRefreshTime])

    useEffect(() => {
        setLoading(true)
    }, [mode])

    const getViews = (f) => {
        name && getRepresentations({name: REPRESENTATION_DICTIONARY_NAME, entityType: name})
            .unwrap()
            .then(res => f && f(res))
    }

    const setFiltersAndSortFromRepresentation = (representation = {}) => {
        const filtersFromRepr = representation.properties?.filter;

        const newFilters = {};

        filtersFromRepr?.forEach(({propName, filter}) => {
            newFilters[propName] = filter
        });

        const newFiltersData = {...newFilters, ...(preFilters || {})}
        setFilters(newFiltersData)
        setCustomFieldsFilters(representation.properties?.customFieldsFilter || [])
        const sort = representation.properties?.sorting || {};
        setSort(sort.propName ? {...sort, name: sort.propName} : {});
        const backlights = representation.properties?.backlightFilter;
        if (backlights) {
            setBacklights(backlights.map(id => ({id})));
            setBacklightsIsFilter(true);
        }

        setTimeout(() => setFiltersFromRepresentationSet(true), 100);
    }

    const setRepresentationById = (representationId) => {
        getViews(res => {
            if (res) {
                const representation = res.find(r => r.id === representationId);
                setRepresentations(res);
                setRepresentation(representation || null)
                if (representation) {
                    setFiltersAndSortFromRepresentation(representation)
                } else getDefaultRepresentation();
                setRepresentationToLocalStorage(name, representation ? representationId : null)
            }
        });
    }

    const getDefaultRepresentation = () => {
        getRepresentations({name: REPRESENTATION_DICTIONARY_NAME, elementName: defaultRepresentationName})
            .unwrap()
            .then(res => {
                setFiltersAndSortFromRepresentation(res ? res[0] : {})
            })
    }

    useEffect(() => {
        if (withFilters) {
            !withoutRepresentations
                ? setRepresentationById(representationId)
                : getDefaultRepresentation();
        }
    }, [name])

    const selectedAll = () => {
        selectedItems.length === totalCount
            ? setSelectedItems([])
            : getIds(getRequestData(0, true))
                .unwrap()
                .then((ids) => {
                    setSelectedItems(ids)
                })
    }

    const setFilterValue = (e, {name, value}) => {
        setFilters((filters) => {
            if (value)
                filters = {...filters, [name]: value};
            else if (filters[name]) {
                filters = Object.assign({}, filters);
                delete filters[name];
            }
            return filters;
        });
    };

    const setCustomFieldFilterValue = (e, {name, value}) => {
        setCustomFieldsFilters(filters => {
            const oldValueIndex = filters.findIndex(f => f.code === name);
            let newFilters = [...filters];
            if (value) {
                newFilters[oldValueIndex < 0 ? filters.length : oldValueIndex] = {
                    code: name,
                    value
                }
            } else {
                newFilters = newFilters.filter((v, i) => i !== oldValueIndex);
            }
            return newFilters;
        })
    }

    const getCustomFieldsFilters = useCallback(() => {
        const filters = {};
        customFieldsFilters.forEach(f => {
            filters[f.code] = f.value
        })
        return filters;
    }, [customFieldsFilters])

    const setSortValue = (e, {name, value, isCustom}) => {
        if (sort.name === name && (sort.desc === (value === 'desc'))) {
            setSort({});
        } else setSort({name, desc: value === 'desc', isCustom});
    };

    const clearFilters = () => {
        setFilters(preFilters || {});
        setCustomFieldsFilters([]);
        setSort({});
        if (backlightsIsFilter) {
            setBacklightsIsFilter(false);
            setBacklights([]);
        }
    };

    const getRequestData = useCallback((skip, isRefresh) => {
        return {
            name,
            filter: {
                columns: columns?.map(c => c.name),
                search: searchQuery,
                gridMode: mode,
                ...filters,
                customFields: customFieldsFilters,
                backlightFilter: backlightsIsFilter ? backlights?.map(b => b.id) : undefined
            },
            sorting: sort && {
                propName: sort.name,
                desc: sort.desc,
                isCustom: sort.isCustom
            },
            paging: {
                skip: isRefresh ? 0 : skip,
                take: isRefresh ? (pageSize > skip ? pageSize : skip) : pageSize
            }
        }
    }, [customFieldsFilters, filters, mode, searchQuery, name, sort, pageSize, backlights, backlightsIsFilter])

    const loadList = (skip = 0, isRefresh) => {
        (!skip && !isRefresh) && scrollTop();

        const requestData = getRequestData(skip, isRefresh)

        getList(requestData);
        withCounts && getCounters({...requestData, paging: null})
    }

    useEffect(() => {
        !isFetching && setLoading(false);
    }, [isFetching])

    const scrollTop = () => {
        const el = document.getElementById('infinity-scroll-table_' + name + idKey);
        el?.scrollTo(0, 0);
    }

    const onChangeSearchValue = (e, {value}) => {
        setSearchQuery(value);
    }

    useEffect(() => {
        setLoading(true);
        setSearchQuery('');
        setMode(defaultMode);
        setFilters(preFilters || {})
        setCustomFieldsFilters([])
    }, [name, defaultMode])

    useEffect(() => {
        filters && setFilters(filters => ({...filters, ...preFilters}));
    }, [preFilters]);

    const nextPage = () => {
        if ((data?.length >= pageSize) && (data?.length < totalCount)) {
            loadList(data.length);
        }
    }

    const refresh = () => loadList(data?.length, true)

    const getCounterRender = (mode) => {
        const counterTab = counterList?.find((item) => item.mode === mode) || {};
        if (Object.keys(counterTab).length !== 0) {
            return `(${counterTab.count}${counterTab.state !== caseStates.PROCESSING_COMPLETED ? `/${counterTab.isExpiredCount}` : ''})`
        }
        return "(0/0)";
    };

    const onDelete = (f) => {
        deleteList({name, ids: selectedItems})
            .unwrap()
            .then((res) => {
                if (!res?.isError) {
                    toast.info(res?.message || t('deleted'));
                } else {
                    toast.error(res?.message || t('error'));
                }
                setSelectedItems([]);
                f && f();
            })
            .catch(e => toast.error(e))
    };

    const onCheck = (row, checked) => {
        setSelectedItems(checked ? [...selectedItems, row.id] : selectedItems.filter(i => i !== row.id));
    }

    const [subtractedHeight, setSubtractedHeight] = useState(0);

    useEffect(() => {
        setSubtractedHeight((refHeader.current?.clientHeight || 0) + (refFooter.current?.clientHeight || 0) + (refCustomFilters.current?.clientHeight || 0))
    }, [refHeader.current?.clientHeight, refFooter.current?.clientHeight, refCustomFilters.current?.clientHeight, actions])

    const table = (tab) => {
        const tableRow = (row, i) => <TableRow
            backlights={backlights}
            className={customClassNameRow && customClassNameRow(row)}
            isSelected={selectedItems.includes(row.id)}
            checkboxes={canDelete}
            onSelect={(e, {checked}) => onCheck(row, checked)}
            key={row.id + i}
            columns={columns}
            row={row}
            load={refresh}
            clickToggle={clickToggle}
            name={name}
            actionFunc={actionFunc}
            changeFieldValue={changeFieldValue}
        />;

        const heightCoef = () => {
            if (isTab) return 195;
            if (tab) return 150;
            else return 70;
        }

        const tableRows = selectedToTop
            ? [
                ...(data || []).filter(r => selectedItems.includes(r.id)),
                ...(data || []).filter(r => !selectedItems.includes(r.id))
            ] : data

        return <InfiniteScrollTable
            style={{
                maxHeight: !isModal ? `calc(100vh - ${heightCoef() + subtractedHeight}px)` : '60vh'
            }}
            id={name + idKey}
            loading={(isFetching && !loading) || deleteLoading}
            className={tab ? 'margin-top-7' : 'margin-top-15'}
            loadNext={nextPage}
            header={
                <TableHeader
                    columns={columns}
                    withFilters={withFilters}
                    filters={filters}
                    customFieldFilters={getCustomFieldsFilters()}
                    setFilter={setFilterValue}
                    setCustomFieldFilter={setCustomFieldFilterValue}
                    sort={sort}
                    setSort={setSortValue}
                    checkboxes={canDelete}
                    selectedAll={!!(totalCount && selectedItems?.length === totalCount)}
                    onSelectAll={selectedAll}
                    disabledFilters={disabledFilters}
                />
            }
        >
            {
                tableRows?.map((row, i) => openModalOnClick
                    ? editBtn(tableRow(row, i), refresh, row.id, mode, row)
                    : tableRow(row, i))
            }
        </InfiniteScrollTable>
    }

    const changeRepresentation = (id) => {
        setFiltersFromRepresentationSet(false);
        setRepresentationById(id)
    }

    return (
        <div
            className={`table-block ${isTab ? 'table-block_tab' : ''} ${noLabel ? 'table-block_without-label' : ''} ${className || ''}`}
        >
            <div ref={refHeader}>
                <TableBlockHeader
                    disabledClear={!(Object.keys(filters).filter(f => (filters[f] !== null) && !disabledFilters?.includes(f)).length || Object.keys(sort).length || customFieldsFilters.length || backlightsIsFilter)}
                    disableCreateWithoutPlatform={disableCreateWithoutPlatform}
                    totalCount={totalCount}
                    isModal={isModal}
                    canDelete={canDelete && !isModal}
                    canCreate={canCreateByForm && !isModal}
                    canCopy={canCopy && !isModal}
                    canExportToExcel={canExportToExcel && !isModal}
                    canImportFromExcel={canImportFromExcel && !isModal}
                    name={name}
                    refresh={refresh}
                    mode={mode}
                    editBtn={editBtn}
                    filters={filters}
                    allColumns={cols}
                    clearFilters={clearFilters}
                    customFilters={!tabs && customFilters}
                    getRequestData={getRequestData}
                    columns={columns}
                    changeRepresentation={changeRepresentation}
                    loadingRepresentation={loadingViewSave}
                    getRepresentationsLoading={getRepresentationsLoading}
                    representation={representation}
                    representations={representations}
                    loadList={loadList}
                    noLabel={!(!isTab && !noLabel)}
                    onDelete={onDelete}
                    searchQuery={searchQuery}
                    onChangeSearchValue={onChangeSearchValue}
                    selectedItems={selectedItems}
                    setFilters={setFilters}
                    setFilterValue={setFilterValue}
                    tabs={tabs}
                    withFilters={withFilters}
                    withoutHeader={withoutHeader}
                    withoutRepresentations={withoutRepresentations}
                    withoutSearch={withoutSearch}
                    importFromExcelFunc={importFromExcelFunc}
                    exportToExcelFunc={exportToExcelFunc}
                    disabledFilters={disabledFilters}
                    changeFieldValue={changeFieldValue}
                    canMassChange={canMassChange && !isModal}
                />
            </div>
            <LoadingSegment isLoading={(isFetching && loading) || !filtersFromRepresentationSet}>
                {
                    customComponent && customComponent({data, cols, refresh: loadList})
                }
                {
                    !customComponent && (tabs && !(tabs.length === 1 && tabs[0].mode === 0)
                        ? <Tab
                            onTabChange={(event, data) => {
                                setMode(data.panes[data.activeIndex].mode)
                            }}
                            className='margin-top-15'
                            panes={tabs.map(tab => ({
                                menuItem: `${t(tab.caption)} ${(tab.isCounterVisible) ? getCounterRender(tab.mode) : ''}`,
                                key: tab.caption,
                                mode: tab.mode,
                                render: () => <Tab.Pane>
                                    {customFilters && customFilters(filters, setFilters, setFilterValue, mode, refCustomFilters)}
                                    {table(tab)}
                                </Tab.Pane>
                            }))}
                        />
                        : table())
                }
            </LoadingSegment>
            {
                (!isModal && withFooter) && <Footer
                    backlightsIsFilter={backlightsIsFilter}
                    setBacklightsIsFilter={setBacklightsIsFilter}
                    setBacklights={withBacklights && setBacklights}
                    backlights={backlights}
                    name={name}
                    actionFunc={actionFunc}
                    refFooter={refFooter}
                    actions={(actions || []).filter(a => a.allowedFromGrid)}
                    loading={actionsLoading}
                    refresh={() => {
                        refresh();
                        getActionsRequest();
                    }}
                />
            }
        </div>
    );
};

export default TableBlock;